import {
    Backdrop,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import closeIcon from "../../assets/icons/cross.svg";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import IconButton from "../utils/button";
import { BUTTON_TYPES, ERROR_MESSAGES } from "../../utils/constants";
import { useForm } from "react-hook-form";
import { useState } from "react";
import dayjs from "dayjs";
import SmallModal from "../tile/smallModal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

const EventModal = (props) => {
    const { title, open, handleClose, submitActions, item } = props;
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [type, setType] = useState();
    const [formValues, setFormValues] = useState({
        title: item?.title || "",
        description: item?.description || "",
        date: item?.date ? dayjs(item?.date) : null,
        beginning: item?.beginning
            ? dayjs(`${item?.date} ${item?.beginning}`)
            : null,
        ending: item?.ending ? dayjs(`${item?.date} ${item?.ending}`) : null,
        location: item?.location || "",
        link: item?.link || "",
        link2: item?.link2 || "",
        type: item?.type,
    });

    const handleChange = (item, value) => {
        setFormValues((prevState) => {
            return {
                ...prevState,
                [item]: value,
            };
        });
    };

    const handleOpenDeleteModal = () => {
        setDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteModalOpen(false);
    };

    const validationSchema = yup.object({
        title: yup.string().required(ERROR_MESSAGES.required),
        date: yup
            .mixed()
            .test("is-null", ERROR_MESSAGES.required, () => {
                return formValues.date !== null;
            })
            .test("is-before-today", ERROR_MESSAGES.invalidDate, () => {
                return dayjs().isBefore(formValues.date, "day");
            }),
        beginning: yup.mixed().test("is-null", ERROR_MESSAGES.required, () => {
            return formValues.beginning !== null;
        }),
        ending: yup
            .mixed()
            .test("is-null", ERROR_MESSAGES.required, () => {
                return formValues.ending !== null;
            })
            .test("is-greater", ERROR_MESSAGES.endingHour, () => {
                return dayjs(formValues.ending).isAfter(
                    dayjs(formValues.beginning)
                );
            }),
        link: yup.string().url(ERROR_MESSAGES.link),
        link2: yup.string().url(ERROR_MESSAGES.link),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });

    const onSubmit = async (data) => {
        const values = {
            ...formValues,
            beginning: `${formValues.beginning["$H"]}:${formValues.beginning["$m"]}`,
            ending: `${formValues.ending["$H"]}:${formValues.ending["$m"]}`,
            type: Number.parseInt(data.type),
        };
        try {
            const action = submitActions[0];
            await action(values);
            handleClose();
            setFormValues({
                title: item?.title || "",
                description: item?.description || "",
                date: dayjs(item?.date) || null,
                beginning: dayjs(`${item?.date} ${item?.beginning}`) || null,
                ending: dayjs(`${item?.date} ${item?.ending}`) || null,
                location: item?.location || "",
                link: item?.link || "",
                link2: item?.link || "",
                type: item?.type || type,
            });
        } catch (error) {
            console.log(error);
            setError("root.serverError", {
                type: "400",
                message: error.message,
            });
        }
    };

    return (
        <>
            <Backdrop open={open} sx={{ zIndex: 2 }}>
                <div className="bg-white shadow-theme flex flex-col gap-5 p-5 rounded-lg z-99">
                    <div className="flex flex-row px-3 justify-between w-full">
                        <h3 className="uppercase text-3xl">{title}</h3>
                        <img
                            src={closeIcon}
                            alt=""
                            onClick={handleClose}
                            className="hover:cursor-pointer"
                            width={20}
                            height={20}
                        />
                    </div>
                    <div className="flex flex-col items-end p-3 gap-3">
                        <TextField
                            className="w-full"
                            variant="outlined"
                            label="Titre de l'événement"
                            required
                            {...register("title")}
                            value={formValues.title}
                            onChange={(e) =>
                                handleChange("title", e.target.value)
                            }
                            error={errors.title?.message}
                            helperText={errors.title?.message}
                        />

                        <TextField
                            className="w-full"
                            variant="outlined"
                            multiline
                            minRows={3}
                            label="Informations sur l'événement"
                            {...register("description")}
                            value={formValues.description}
                            onChange={(e) =>
                                handleChange("description", e.target.value)
                            }
                        />
                        <div className="flex flex-row gap-2 w-full">
                            <DatePicker
                                label="Date"
                                views={["year", "month", "day"]}
                                disablePast={true}
                                {...register("date")}
                                value={formValues.date}
                                onChange={(value) => {
                                    handleChange("date", value);
                                }}
                                slotProps={{
                                    textField: {
                                        required: true,
                                        helperText: errors.date?.message,
                                        error: errors.date?.message,
                                    },
                                }}
                            />
                            <TimePicker
                                label="Heure de début"
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                }}
                                slotProps={{
                                    textField: {
                                        required: true,
                                        error: errors.beginning?.message,
                                        helperText: errors.beginning?.message,
                                    },
                                }}
                                {...register("beginning")}
                                value={formValues.beginning}
                                onChange={(value) => {
                                    handleChange("beginning", value);
                                }}
                            />
                            <TimePicker
                                label="Heure de fin"
                                viewRenderers={{
                                    hours: renderTimeViewClock,
                                    minutes: renderTimeViewClock,
                                }}
                                slotProps={{
                                    textField: {
                                        required: true,
                                        error: errors.ending?.message,
                                        helperText: errors.ending?.message,
                                    },
                                }}
                                {...register("ending")}
                                value={formValues.ending}
                                onChange={(value) =>
                                    handleChange("ending", value)
                                }
                            />
                            <FormControl className="w-2/5">
                                <InputLabel id="type-select-label">
                                    {" Type d'événement"}
                                </InputLabel>
                                <Select
                                    labelId="type-select-label"
                                    id="type"
                                    label="Type d'événement"
                                    defaultValue={item?.type}
                                    value={type}
                                    onChange={(e) => {
                                        setType(e.target.value);
                                    }}
                                    {...register("type")}
                                >
                                    <MenuItem value={0}>Événement</MenuItem>
                                    <MenuItem value={1}>Initiation</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className="flex flex-row justify-between w-full gap-2">
                            <TextField
                                className="w-1/3"
                                variant="outlined"
                                label="Lieu de l'événement"
                                {...register("location")}
                                value={formValues.location}
                                onChange={(e) =>
                                    handleChange("location", e.target.value)
                                }
                                error={errors.location?.message}
                                helperText={errors.location?.message}
                            />
                            <TextField
                                className="w-1/3"
                                variant="outlined"
                                label="Lien d'inscription"
                                {...register("link")}
                                value={formValues.link}
                                onChange={(e) =>
                                    handleChange("link", e.target.value)
                                }
                                error={errors.link?.message}
                                helperText={errors.link?.message}
                            />
                            <TextField
                                className="w-1/3"
                                variant="outlined"
                                label="Lien d'inscription 2"
                                {...register("link2")}
                                value={formValues.link2}
                                onChange={(e) =>
                                    handleChange("link2", e.target.value)
                                }
                                error={errors.link2?.message}
                                helperText={errors.link2?.message}
                            />
                        </div>
                        {errors.root?.serverError && (
                            <p className="text-red text-xs">
                                ${errors.root.serverError.message}
                            </p>
                        )}
                        <div className="flex flex-row gap-2">
                            {submitActions.length === 2 && (
                                <IconButton
                                    type={BUTTON_TYPES.delete}
                                    onClick={handleOpenDeleteModal}
                                />
                            )}
                            <IconButton
                                type={BUTTON_TYPES.save}
                                onClick={handleSubmit(onSubmit)}
                            />
                        </div>
                    </div>
                </div>
            </Backdrop>
            {submitActions.length === 2 && (
                <SmallModal
                    open={deleteModalOpen}
                    handleClose={() => {
                        handleCloseDeleteModal();
                        handleClose();
                    }}
                    title={`suppression de ${item.title}`}
                    submitAction={submitActions[1]}
                />
            )}
        </>
    );
};

export default EventModal;
