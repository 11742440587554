import {
    Backdrop,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import closeIcon from "../../assets/icons/cross.svg";
import { BUTTON_TYPES } from "../../utils/constants";
import { useForm } from "react-hook-form";
import IconButton from "../utils/button";

const STATUS = {
    0: { label: "À préparer", color: "bg-red" },
    1: { label: "En préparation", color: "bg-yellow" },
    2: { label: "À expédier", color: "bg-blue" },
    3: { label: "Expédiée", color: "bg-green" },
    4: { label: "Traitée", color: "bg-purple" },
};

const PAIEMENT_TYPES = {
    0: "Carte bancaire",
    1: "PayPal",
};

const calcTotalPrice = (order) => {
    let total = 0;
    order.OrderedProducts.map((orderedProduct) => {
        const quantity = orderedProduct.quantity;
        const price = orderedProduct.Product.price;
        total += quantity * price;
    });
    return total;
};

const parseDate = (data) => {
    const date = new Date(data);
    return `${
        date.getDate().toString().length === 1
            ? "0" + date.getDate().toString()
            : date.getDate().toString()
    }/${
        date.getUTCMonth().toString().length === 1
            ? "0" + (date.getUTCMonth() + 1)
            : date.getUTCMonth() + 1
    }/${date.getFullYear()}`;
};

const OrderModal = (props) => {
    const { title, open, handleClose, handleUpdate, item } = props;

    const { handleSubmit, register } = useForm();

    const onSubmit = async (data) => {
        try {
            handleUpdate(data);
            handleClose();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Backdrop open={open} sx={{ zIndex: 2 }}>
            <div className="bg-white shadow-theme flex flex-col gap-3 p-5 rounded-lg z-99 w-large">
                <div className="flex flex-row px-3 justify-between w-full">
                    <h3 className="uppercase text-3xl">{title}</h3>
                    <img
                        src={closeIcon}
                        alt=""
                        onClick={handleClose}
                        className="hover:cursor-pointer"
                        width={20}
                        height={20}
                    />
                </div>
                <div className="flex flex-row p-3 gap-3 w-full items-start">
                    <div className="flex flex-col gap-3 w-5/12">
                        <div className="flex flex-col gap-1 p-3 pr-10 rounded-md bg-lightgrey w-full">
                            <h3 className="text-xl">Informations du client</h3>
                            <p>
                                <strong>Nom : </strong>
                                {item.clientLastname}
                            </p>
                            <p>
                                <strong>Prénom : </strong>
                                {item.clientFirstname}
                            </p>
                            <p>
                                <strong>E-mail : </strong>
                                {item.clientEmail}
                            </p>
                            <p>
                                <strong>Tél. : </strong>
                                {item.clientPhone}
                            </p>
                        </div>
                        <div className="flex flex-col gap-1 p-3 pr-10 rounded-md bg-lightgrey w-full">
                            <h3 className="text-lg">
                                Informations de la commande
                            </h3>
                            <p>
                                <strong>Date : </strong>
                                {parseDate(item.createdAt)}
                            </p>
                            <p>
                                <strong>
                                    Quantité de produits différents :{" "}
                                </strong>
                                {item.OrderedProducts.length}
                            </p>
                            <p>
                                <strong>Total panier : </strong>
                                {calcTotalPrice(item)}€
                            </p>
                            <p>
                                <strong>Frais de livraison : </strong>
                                {item.shipping}€
                            </p>
                            <p>
                                <strong>Total TTC : </strong>
                                {calcTotalPrice(item) +
                                    Number.parseFloat(item.shipping)}
                                €
                            </p>
                            <p>
                                <strong>Type de paiement : </strong>
                                {PAIEMENT_TYPES[item.payedBy]}
                            </p>
                        </div>
                        <div className="bg-yellow font-semibold p-3 text-center rounded-md hover:cursor-pointer">
                            {"TÉLÉCHARGER LE BON D'ENVOI"}
                        </div>
                    </div>
                    <Table className="w-7/12">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">
                                    <p className="font-bold">Réf. produit</p>
                                </TableCell>
                                <TableCell align="center">
                                    <p className="font-bold">Nom</p>
                                </TableCell>
                                <TableCell align="center">
                                    <p className="font-bold">P.U. (€)</p>
                                </TableCell>
                                <TableCell align="center">
                                    <p className="font-bold">Quantité</p>
                                </TableCell>
                                <TableCell align="center">
                                    <p className="font-bold">Prix total (€)</p>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {item.OrderedProducts.map((orderedProduct, i) => (
                                <TableRow key={i}>
                                    <TableCell align="center">
                                        {orderedProduct.Product.ref}
                                    </TableCell>
                                    <TableCell align="center">
                                        {orderedProduct.Product.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {orderedProduct.Product.price}
                                    </TableCell>
                                    <TableCell align="center">
                                        {orderedProduct.quantity}
                                    </TableCell>
                                    <TableCell align="center">
                                        {orderedProduct.quantity *
                                            orderedProduct.Product.price}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
                <div className="flex flex-row justify-end gap-5 w-full">
                    <FormControl>
                        <InputLabel
                            id="label-for-filter"
                            sx={{
                                backgroundColor: "white",
                                padding: "0px 4px",
                            }}
                        >
                            Modifier le status
                        </InputLabel>
                        <Select
                            labelId="label-for-filter"
                            id="filter-select"
                            defaultValue={item.status}
                            className="w-48"
                            {...register("status")}
                        >
                            {Object.entries(STATUS).map((item, i) => {
                                return (
                                    <MenuItem
                                        key={i}
                                        value={Number.parseInt(item[0])}
                                    >
                                        <div className="flex flex-row items-center gap-3">
                                            <div
                                                className={`rounded-full w-4 h-4 ${item[1].color}`}
                                            ></div>
                                            <p>{item[1].label}</p>
                                        </div>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <IconButton
                        type={BUTTON_TYPES.save}
                        onClick={handleSubmit(onSubmit)}
                    />
                </div>
            </div>
        </Backdrop>
    );
};

export default OrderModal;
