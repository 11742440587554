import { useMemo, useRef, useState } from "react";
import {
    BASE_URL,
    BUTTON_TYPES,
    ERROR_MESSAGES,
    URLS,
} from "../../utils/constants";
import closeIcon from "../../assets/icons/cross.svg";
import {
    Autocomplete,
    Backdrop,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import IconButton from "../utils/button";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import SmallModal from "../tile/smallModal";

const ProductModal = (props) => {
    const { title, open, handleClose, item, submitActions } = props;
    const fileInputRef = useRef(null);
    const [editorsOption, setEditorsOption] = useState([]);
    const [editorInput, setEditorInput] = useState("");
    const [categoriesOption, setCategoriesOption] = useState([]);
    const [image, setImage] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [formValues, setFormValues] = useState({
        ref: item?.ref || "",
        name: item?.name || "",
        editor: null,
        description: item?.description || "",
        stockStore:
            typeof item?.stockStore === "number" ? item.stockStore : undefined,
        stockOnline:
            typeof item?.stockOnline === "number"
                ? item?.stockOnline
                : undefined,
        weight: item?.weight || undefined,
        minPlayer: item?.minPlayer || undefined,
        maxPlayer: item?.maxPlayer || "",
        ageMin: item?.ageMin || undefined,
        averageGame:
            typeof item?.averageGame === "number"
                ? item?.averageGame
                : undefined,
        price: Number.parseFloat(item?.price).toFixed(2) || undefined,
        purchasePrice:
            Number.parseFloat(item?.purchasePrice).toFixed(2) || undefined,
        image: item?.image !== undefined ? `${BASE_URL}/${item?.image}` : "",
    });

    const validationSchema = yup.object().shape({
        image: yup
            .mixed()
            .test("image-requirede", ERROR_MESSAGES.required, () => {
                return image !== null || formValues.image !== "";
            }),
        ref: yup.string().required(ERROR_MESSAGES.required),
        name: yup.string().required(ERROR_MESSAGES.required),
        editor: yup.string().required(ERROR_MESSAGES.required),
        description: yup.string().required(ERROR_MESSAGES.required),
        stockStore: yup.string().required(ERROR_MESSAGES.required),
        stockOnline: yup.string().required(ERROR_MESSAGES.required),
        weight: yup
            .number()
            .nullable()
            .integer(ERROR_MESSAGES.integer)
            .moreThan(0, ERROR_MESSAGES.positive)
            .transform((_, val) => (val !== "" ? Number(val) : null))
            .required(ERROR_MESSAGES.required),
        minPlayer: yup
            .number()
            .nullable()
            .integer(ERROR_MESSAGES.integer)
            .moreThan(0, ERROR_MESSAGES.positive)
            .transform((_, val) => (val !== "" ? Number(val) : null)),
        maxPlayer: yup
            .number()
            .nullable()
            .integer(ERROR_MESSAGES.integer)
            .transform((_, val) => (val !== "" ? Number(val) : null))
            .test("is-greater", ERROR_MESSAGES.minPlayer, (value) => {
                if (value === "") return true;
                return (
                    formValues.minPlayer === undefined ||
                    formValues.minPlayer === null ||
                    formValues.minPlayer <= value
                );
            }),
        ageMin: yup
            .number()
            .nullable()
            .integer(ERROR_MESSAGES.integer)
            .moreThan(0, ERROR_MESSAGES.positive)
            .transform((_, val) => (val !== "" ? Number(val) : null)),
        price: yup
            .number()
            .nullable()
            .moreThan(0, ERROR_MESSAGES.positive)
            .transform((_, val) => (val !== "" ? Number(val) : null))
            .required(ERROR_MESSAGES.required),
        purchasePrice: yup.number().nullable(),
        category: yup.string().required(ERROR_MESSAGES.required),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        setValue,
        resetField,
    } = useForm({
        mode: "onSubmit",
        reValidateMode: "onSubmit",
        resolver: yupResolver(validationSchema),
    });

    useMemo(() => {
        resetField("ref");
        resetField("name");
        resetField("description");
        resetField("stockStore");
        resetField("stockOnline");
        resetField("weight");
        resetField("minPlayer");
        resetField("maxPlayer");
        resetField("ageMin");
        resetField("averageGame");
        resetField("price");
        resetField("purchasePrice");
        setImage(null);
    }, [resetField, open]);

    const { ref, ...rest } = register("image");

    const handleOpenModal = () => {
        setModalOpen(true);
    };
    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const loadCategories = async () => {
        try {
            const response = await axios.get(URLS.category);
            setCategoriesOption(response.data.data);
        } catch (error) {
            console.log("error categories", error);
        }
    };

    const loadEditors = async () => {
        try {
            const response = await axios.get(URLS.editor);
            setEditorsOption(response.data.data);
            if (item) {
                const currentEditor = response.data.data.filter(
                    (option) => option.id === item.EditorId
                )[0];
                handleChange("editor", currentEditor);
                setValue("editor", currentEditor.name);
            }
        } catch (error) {
            console.log("error editor", error);
        }
    };

    const uploadImage = async () => {
        if (image === null && formValues.image !== "") {
            return formValues.image.replace(
                "https://api.univers-kids-troyes.com/",
                ""
            );
        } else {
            const formData = new FormData();
            formData.append("image", image.file);
            const filePath = axios
                .post(`${BASE_URL}/upload`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    handleChange("image", response.data.filePath);
                    return response.data.filePath;
                })
                .catch((error) => {
                    console.error("Error uploading file", error);
                });
            return filePath;
        }
    };

    const handleChange = (item, value) => {
        setFormValues((prevState) => {
            return {
                ...prevState,
                [item]: value,
            };
        });
    };

    const handleChangeImage = (e) => {
        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            file: e.target.files[0],
        });
    };

    const onSubmit = async (data) => {
        const imagePath = await uploadImage();
        const values = {
            ...formValues,
            image: imagePath,
            ageMin: Number.parseInt(formValues.ageMin),
            minPlayer: Number.parseInt(formValues.minPlayer),
            maxPlayer: Number.parseInt(formValues.maxPlayer),
            stockStore: Number.parseInt(formValues.stockStore),
            stockOnline: Number.parseInt(formValues.stockOnline),
            weight: Number.parseFloat(formValues.weight),
            price: Number.parseFloat(formValues.price).toFixed(2),
            purchasePrice: Number.parseFloat(formValues.purchasePrice).toFixed(
                2
            ),
            category: data.category,
            editor: formValues.editor.id,
        };
        try {
            const action = submitActions[0];
            await action(values);
            handleClose();
            setFormValues({
                ref: item?.ref || "",
                name: item?.name || "",
                editor: item?.editorId || "",
                description: item?.description || "",
                stockStore: item?.stockStore || null,
                stockOnline: item?.stockOnline || null,
                weight: item?.weight || undefined,
                minPlayer: item?.minPlayer || null,
                maxPlayer: item?.maxPlayer || null,
                ageMin: item?.ageMin || null,
                averageGame: item?.averageGame || null,
                price: item?.price || null,
                purchasePrice: item?.purchasePrice || null,
                image: `${BASE_URL}/${item?.image}` || null,
            });
        } catch (error) {
            console.log(error);
            setError("ref", {
                type: "400",
                message: error?.message,
            });
        }
    };

    useMemo(() => {
        loadCategories();
        loadEditors();
    }, [item]);

    return (
        <>
            <Backdrop open={open} sx={{ zIndex: 2 }}>
                <div className="bg-white shadow-theme flex flex-col gap-5 p-5 rounded-lg z-99">
                    <div className="flex flex-row px-3 justify-between w-full">
                        <h3 className="uppercase text-3xl">{title}</h3>
                        <img
                            src={closeIcon}
                            alt=""
                            onClick={handleClose}
                            className="hover:cursor-pointer"
                            width={20}
                            height={20}
                        />
                    </div>
                    <div className="flex flex-col items-end p-3 gap-3 w-full">
                        <div className="flex flex-row gap-3 w-full">
                            <div className="flex flex-col justify-between w-1/4">
                                <img
                                    src={
                                        image === null &&
                                        formValues.image !== ""
                                            ? formValues.image
                                            : image?.preview
                                    }
                                    alt="Image du produit"
                                    className="bg-grey rounded-md flex justify-center items-center h-60 w-60"
                                />
                                <div className="flex flex-col gap-0">
                                    <IconButton
                                        type={BUTTON_TYPES.addImage}
                                        onClick={() =>
                                            fileInputRef.current.click()
                                        }
                                    />
                                    <input
                                        {...rest}
                                        type="file"
                                        accept="image/png, image/jpeg, image/jpg, image/webp"
                                        hidden
                                        onChange={handleChangeImage}
                                        multiple={false}
                                        ref={(e) => {
                                            ref(e);
                                            fileInputRef.current = e;
                                        }}
                                    />
                                    {errors.image && (
                                        <p className="text-red text-xs">
                                            {errors.image.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="flex flex-col gap-3 w-full">
                                <div className="flex flex-row gap-3">
                                    <TextField
                                        className="w-full"
                                        variant="outlined"
                                        label="Référence"
                                        required
                                        {...register("ref")}
                                        value={formValues.ref}
                                        onChange={(e) =>
                                            handleChange("ref", e.target.value)
                                        }
                                        error={errors.ref?.message}
                                        helperText={errors.ref?.message}
                                    />
                                    <TextField
                                        className="w-full"
                                        variant="outlined"
                                        label="Nom"
                                        required
                                        {...register("name")}
                                        value={formValues.name}
                                        onChange={(e) =>
                                            handleChange("name", e.target.value)
                                        }
                                        error={errors.name?.message}
                                        helperText={errors.name?.message}
                                    />
                                    <Autocomplete
                                        className="w-full"
                                        id="editor"
                                        isOptionEqualToValue={(
                                            option,
                                            value
                                        ) => {
                                            value instanceof String
                                                ? option.id ===
                                                  JSON.parse(value)
                                                : option.id === value;
                                        }}
                                        getOptionLabel={(option) =>
                                            option.name || ""
                                        }
                                        options={editorsOption}
                                        value={formValues.editor}
                                        onChange={(e, newValue) => {
                                            handleChange("editor", newValue);
                                        }}
                                        inputValue={editorInput}
                                        onInputChange={(
                                            event,
                                            nextInputValue
                                        ) => {
                                            setEditorInput(nextInputValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Éditeur"
                                                required
                                                {...register("editor")}
                                                error={errors.editor?.message}
                                                helperText={
                                                    errors.editor?.message
                                                }
                                            />
                                        )}
                                    />
                                </div>
                                <TextField
                                    className="w-full"
                                    variant="outlined"
                                    label="Description"
                                    required
                                    multiline
                                    minRows={6}
                                    maxRows={6}
                                    {...register("description")}
                                    value={formValues.description}
                                    onChange={(e) =>
                                        handleChange(
                                            "description",
                                            e.target.value
                                        )
                                    }
                                    error={errors.description?.message}
                                    helperText={errors.description?.message}
                                />
                                <div className="flex flex-row gap-3 w-full">
                                    <TextField
                                        className="w-1/4"
                                        variant="outlined"
                                        label="Stock magasin"
                                        required
                                        inputProps={{
                                            type: "number",
                                            min: "0",
                                            inputMode: "numeric",
                                            pattern: "[0-9]*",
                                        }}
                                        {...register("stockStore")}
                                        value={formValues.stockStore}
                                        onInput={(e) => {
                                            const newValue = e.target.value;
                                            const sanitizedValue =
                                                newValue.replace(/[^0-9]/g, "");
                                            handleChange(
                                                "stockStore",
                                                sanitizedValue
                                            );
                                        }}
                                        onKeyDown={(event) => {
                                            if (
                                                !/[0-9]/.test(event.key) &&
                                                event.key !== "Backspace" &&
                                                event.key !== "ArrowLeft" &&
                                                event.key !== "ArrowRight" &&
                                                event.key !== "Delete" &&
                                                event.key !== "Tab"
                                            ) {
                                                event.preventDefault();
                                            }
                                        }}
                                        error={errors.stockStore?.message}
                                        helperText={errors.stockStore?.message}
                                    />
                                    <TextField
                                        className="w-1/4"
                                        variant="outlined"
                                        label="Stock en ligne"
                                        required
                                        inputProps={{
                                            type: "number",
                                            min: "0",
                                        }}
                                        {...register("stockOnline")}
                                        value={formValues.stockOnline}
                                        onInput={(e) => {
                                            const newValue = e.target.value;
                                            const sanitizedValue =
                                                newValue.replace(/[^0-9]/g, "");
                                            handleChange(
                                                "stockOnline",
                                                sanitizedValue
                                            );
                                        }}
                                        onKeyDown={(event) => {
                                            if (
                                                !/[0-9]/.test(event.key) &&
                                                event.key !== "Backspace" &&
                                                event.key !== "ArrowLeft" &&
                                                event.key !== "ArrowRight" &&
                                                event.key !== "Delete" &&
                                                event.key !== "Tab"
                                            ) {
                                                event.preventDefault();
                                            }
                                        }}
                                        error={errors.stockOnline?.message}
                                        helperText={errors.stockOnline?.message}
                                    />
                                    <TextField
                                        className="w-1/4"
                                        variant="outlined"
                                        label="Poids (grammes)"
                                        required
                                        inputProps={{
                                            type: "number",
                                            step: "100",
                                            min: "0",
                                        }}
                                        {...register("weight")}
                                        value={formValues.weight}
                                        onInput={(e) => {
                                            const newValue = e.target.value;
                                            const sanitizedValue =
                                                newValue.replace(/[^0-9]/g, "");
                                            handleChange(
                                                "weight",
                                                sanitizedValue
                                            );
                                        }}
                                        onKeyDown={(event) => {
                                            if (
                                                !/[0-9]/.test(event.key) &&
                                                event.key !== "Backspace" &&
                                                event.key !== "ArrowLeft" &&
                                                event.key !== "ArrowRight" &&
                                                event.key !== "Delete" &&
                                                event.key !== "Tab"
                                            ) {
                                                event.preventDefault();
                                            }
                                        }}
                                        error={errors.weight?.message}
                                        helperText={errors.weight?.message}
                                    />
                                    <TextField
                                        className="w-1/4"
                                        variant="outlined"
                                        label="Prix d'achat (HT)"
                                        inputProps={{
                                            type: "number",
                                            step: "0.10",
                                            min: "0.00",
                                        }}
                                        {...register("purchasePrice")}
                                        value={formValues.purchasePrice}
                                        onInput={(e) => {
                                            const newValue = e.target.value;
                                            handleChange(
                                                "purchasePrice",
                                                newValue
                                            );
                                        }}
                                        onKeyDown={(event) => {
                                            if (
                                                !/^\d*\.?\d{0,2}$/.test(
                                                    event.key
                                                ) &&
                                                event.key !== "Backspace" &&
                                                event.key !== "ArrowLeft" &&
                                                event.key !== "ArrowRight" &&
                                                event.key !== "Delete" &&
                                                event.key !== "Tab"
                                            ) {
                                                event.preventDefault();
                                            }
                                        }}
                                        error={errors.purchasePrice?.message}
                                        helperText={
                                            errors.purchasePrice?.message
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row justify-between w-full border">
                            <TextField
                                className="w-2/12"
                                variant="outlined"
                                label="Minimum de joueurs"
                                inputProps={{ type: "number", min: "1" }}
                                {...register("minPlayer")}
                                value={formValues.minPlayer}
                                onInput={(e) => {
                                    const newValue = e.target.value;
                                    const sanitizedValue = newValue.replace(
                                        /[^0-9]/g,
                                        ""
                                    );
                                    handleChange("minPlayer", sanitizedValue);
                                }}
                                onKeyDown={(event) => {
                                    if (
                                        !/[0-9]/.test(event.key) &&
                                        event.key !== "Backspace" &&
                                        event.key !== "ArrowLeft" &&
                                        event.key !== "ArrowRight" &&
                                        event.key !== "Delete" &&
                                        event.key !== "Tab"
                                    ) {
                                        event.preventDefault();
                                    }
                                }}
                                error={errors.minPlayer?.message}
                                helperText={errors.minPlayer?.message}
                            />
                            <TextField
                                className="w-2/12"
                                variant="outlined"
                                label="Maximum de joueurs"
                                inputProps={{ type: "number", min: "1" }}
                                {...register("maxPlayer")}
                                value={formValues.maxPlayer}
                                onInput={(e) => {
                                    const newValue = e.target.value;
                                    const sanitizedValue = newValue.replace(
                                        /[^0-9]/g,
                                        ""
                                    );
                                    handleChange("maxPlayer", sanitizedValue);
                                }}
                                onKeyDown={(event) => {
                                    if (
                                        !/[0-9]/.test(event.key) &&
                                        event.key !== "Backspace" &&
                                        event.key !== "ArrowLeft" &&
                                        event.key !== "ArrowRight" &&
                                        event.key !== "Delete" &&
                                        event.key !== "Tab"
                                    ) {
                                        event.preventDefault();
                                    }
                                }}
                                error={errors.maxPlayer?.message}
                                helperText={errors.maxPlayer?.message}
                            />
                            <TextField
                                className="w-2/12"
                                variant="outlined"
                                label="Âge minimum"
                                inputProps={{ type: "number", min: "1" }}
                                {...register("ageMin")}
                                value={formValues.ageMin}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    const sanitizedValue = newValue.replace(
                                        /[^0-9]/g,
                                        ""
                                    );
                                    handleChange("ageMin", sanitizedValue);
                                }}
                                onKeyDown={(event) => {
                                    if (
                                        !/[0-9]/.test(event.key) &&
                                        event.key !== "Backspace" &&
                                        event.key !== "ArrowLeft" &&
                                        event.key !== "ArrowRight" &&
                                        event.key !== "Delete" &&
                                        event.key !== "Tab"
                                    ) {
                                        event.preventDefault();
                                    }
                                }}
                                error={errors.ageMin?.message}
                                helperText={errors.ageMin?.message}
                            />
                            <TextField
                                className="w-2/12"
                                variant="outlined"
                                label="Durée moyenne d'une partie (min)"
                                {...register("averageGame")}
                                value={formValues.averageGame}
                                inputProps={{
                                    type: "number",
                                    step: "5",
                                    min: "0",
                                }}
                                onInput={(e) => {
                                    const newValue = e.target.value;
                                    const sanitizedValue = newValue.replace(
                                        /[^0-9]/g,
                                        ""
                                    );
                                    handleChange("averageGame", sanitizedValue);
                                }}
                                onKeyDown={(event) => {
                                    if (
                                        !/[0-9]/.test(event.key) &&
                                        event.key !== "Backspace" &&
                                        event.key !== "ArrowLeft" &&
                                        event.key !== "ArrowRight" &&
                                        event.key !== "Delete" &&
                                        event.key !== "Tab"
                                    ) {
                                        event.preventDefault();
                                    }
                                }}
                                error={errors.averageGame?.message}
                                helperText={errors.averageGame?.message}
                            />
                            <FormControl
                                className="w-2/12"
                                error={errors.category?.message}
                            >
                                <InputLabel id="category-select-label">
                                    Catégorie *
                                </InputLabel>
                                <Select
                                    labelId="category-select-label"
                                    id="category"
                                    label="Catégorie"
                                    inputProps={{ ...register("category") }}
                                    defaultValue={item?.CategoryId || ""}
                                >
                                    {categoriesOption.map((item, i) => (
                                        <MenuItem key={i} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.category && (
                                    <FormHelperText>
                                        {errors.category.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <TextField
                                className="w-1/12"
                                variant="outlined"
                                label="Prix"
                                inputProps={{
                                    type: "number",
                                    step: "0.10",
                                    min: "0.10",
                                }}
                                required
                                {...register("price")}
                                value={formValues.price}
                                onInput={(e) => {
                                    const newValue = e.target.value;
                                    handleChange("price", newValue);
                                }}
                                onKeyDown={(event) => {
                                    if (
                                        !/^\d*\.?\d{0,2}$/.test(event.key) &&
                                        event.key !== "Backspace" &&
                                        event.key !== "ArrowLeft" &&
                                        event.key !== "ArrowRight" &&
                                        event.key !== "Delete" &&
                                        event.key !== "Tab"
                                    ) {
                                        event.preventDefault();
                                    }
                                }}
                                error={errors.price?.message}
                                helperText={errors.price?.message}
                            />
                        </div>
                        <div className="flex flex-row w-fit gap-3">
                            {submitActions.length === 2 && (
                                <IconButton
                                    type={BUTTON_TYPES.delete}
                                    onClick={handleOpenModal}
                                />
                            )}
                            <IconButton
                                type={BUTTON_TYPES.save}
                                onClick={handleSubmit(onSubmit)}
                            />
                        </div>
                    </div>
                </div>
            </Backdrop>
            {submitActions.length === 2 && (
                <SmallModal
                    open={modalOpen}
                    handleClose={() => {
                        handleCloseModal();
                        handleClose();
                    }}
                    title={`suppression de ${formValues.name}`}
                    type="delete"
                    submitAction={submitActions[1]}
                />
            )}
        </>
    );
};

export default ProductModal;
