import { useEffect, useState } from "react";
import { URLS } from "../utils/constants";
import PageTitle from "../components/utils/pageTitle";
import {
    FormControl,
    InputLabel,
    MenuItem,
    Pagination,
    Select,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import Notification from "../components/utils/notification";
import OrderModal from "../components/order/orderModal";
import { useDataContext } from "../data/DataContext";

const STATUS = {
    0: { label: "À préparer", color: "bg-red" },
    1: { label: "En préparation", color: "bg-yellow" },
    2: { label: "À expédier", color: "bg-blue" },
    3: { label: "Expédiée", color: "bg-green" },
    4: { label: "Traitée", color: "bg-purple" },
};

const calcTotalPrice = (order) => {
    let total = 0;
    order.OrderedProducts?.map((orderedProduct) => {
        const quantity = orderedProduct.quantity;
        const price = orderedProduct.Product.price;
        total += quantity * price;
    });
    return total;
};

const parseDate = (data) => {
    const date = new Date(data);
    return `${
        date.getDate().toString().length === 1
            ? "0" + date.getDate().toString()
            : date.getDate().toString()
    }/${
        date.getUTCMonth().toString().length === 1
            ? "0" + (date.getUTCMonth() + 1)
            : date.getUTCMonth() + 1
    }/${date.getFullYear()}`;
};

const Orders = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState("default");
    const [filter, setFilter] = useState("default");
    const [notification, setNotification] = useState(false);
    const [options, setOptions] = useState([]);
    const { state, fetchDataWithParams, updateData } = useDataContext();
    const [selectedItem, setSelectedItem] = useState();

    const handleOpen = () => {
        setModalOpen(true);
    };

    const handleClose = () => {
        setSelectedItem();
        setModalOpen(false);
    };

    const handleCloseNotification = () => {
        setNotification(false);
    };

    const onUpdate = async (data) => {
        await updateData(URLS.order, selectedItem, data);
        fetchDataWithParams(URLS.order, options);
        setNotification(true);
    };

    useEffect(() => {
        fetchDataWithParams(URLS.order, options);
    }, [options]);

    useEffect(() => {
        setOptions((prevState) => {
            return {
                ...prevState,
                orderBy: sort,
            };
        });
    }, [sort]);

    useEffect(() => {
        setOptions((prevState) => {
            return {
                ...prevState,
                whereClause: { status: filter },
            };
        });
    }, [filter]);

    useEffect(() => {
        setOptions((prevState) => {
            return {
                ...prevState,
                offset: (page - 1) * 10,
            };
        });
    }, [page]);

    return (
        <div className="flex flex-col gap-5 justify-center items-center">
            <div className="flex flex-row py-12 justify-between w-full">
                {state.loading ? (
                    <Skeleton variant="rounded" height={50} width={220} />
                ) : (
                    <div className="flex flex-row gap-16 items-baseline">
                        <PageTitle title={`${state.count} commande(s)`} />
                    </div>
                )}
                <div className="flex flex-row gap-3 w-1/3">
                    <FormControl fullWidth>
                        <InputLabel
                            id="label-for-order"
                            shrink
                            sx={{ backgroundColor: "white", padding: "0 4px" }}
                        >
                            Trier par
                        </InputLabel>
                        <Select
                            labelId="label-for-order"
                            id="order-select"
                            value={sort}
                            onChange={(e) => setSort(e.target.value)}
                        >
                            <MenuItem value={"status"}>Status</MenuItem>
                            <MenuItem value={"default"}>
                                Date de commande
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel
                            id="label-for-filter"
                            shrink={true}
                            sx={{ backgroundColor: "white", padding: "0 4px" }}
                        >
                            Filtrer par status
                        </InputLabel>
                        <Select
                            labelId="label-for-filter"
                            id="filter-select"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        >
                            <MenuItem value={"default"}>Aucun filtre</MenuItem>
                            {Object.entries(STATUS).map((item, i) => (
                                <MenuItem
                                    key={i}
                                    value={Number.parseInt(item[0])}
                                >
                                    <div className="flex flex-row items-center gap-3">
                                        <div
                                            className={`rounded-full w-4 h-4 ${item[1].color}`}
                                        ></div>
                                        <p>{item[1].label}</p>
                                    </div>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className="flex flex-col gap-5 justify-center items-center w-full">
                {state.error && <p>{state.error}</p>}
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                <p className="font-bold">Réf. commande</p>
                            </TableCell>
                            <TableCell align="center">
                                <p className="font-bold">Nom du client</p>
                            </TableCell>
                            <TableCell align="center">
                                <p className="font-bold">Prix TTC</p>
                            </TableCell>
                            <TableCell align="center">
                                <p className="font-bold">Date</p>
                            </TableCell>
                            <TableCell align="center">
                                <p className="font-bold">Status</p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.loading ? (
                            <TableRow>
                                <TableCell colSpan={5}>
                                    <Skeleton
                                        variant="rounded"
                                        height={50}
                                        width="100%"
                                    />
                                </TableCell>
                            </TableRow>
                        ) : (
                            state.data.map((item, i) => (
                                <TableRow
                                    key={i}
                                    onClick={() => {
                                        setSelectedItem(item);
                                        handleOpen();
                                    }}
                                    className="hover:cursor-pointer hover:bg-lightgrey"
                                >
                                    <TableCell align="center">
                                        {item.id}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.clientLastname}
                                    </TableCell>
                                    <TableCell align="center">
                                        {`${
                                            calcTotalPrice(item) +
                                            Number.parseFloat(item.shipping)
                                        }€`}
                                    </TableCell>
                                    <TableCell align="center">
                                        {parseDate(item.createdAt)}
                                    </TableCell>
                                    <TableCell align="center">
                                        <div className="flex flex-row items-center gap-3 justify-center">
                                            <div
                                                className={`rounded-full w-4 h-4 ${
                                                    STATUS[item.status]?.color
                                                }`}
                                            ></div>
                                            {STATUS[item.status]?.label}
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
                {selectedItem && (
                    <OrderModal
                        title={`modification de la commande n°${selectedItem.id}`}
                        item={selectedItem}
                        open={modalOpen}
                        handleClose={handleClose}
                        handleUpdate={onUpdate}
                    />
                )}
                {(state.status === 200 || state.status === 201) && (
                    <Pagination
                        count={Math.floor((state.count + 9) / 10)}
                        page={page}
                        onChange={(event, value) => setPage(value)}
                    />
                )}
                {state.status === 201 && (
                    <Notification
                        message="Modification réussie !"
                        isOpen={notification}
                        onClose={handleCloseNotification}
                    />
                )}
            </div>
        </div>
    );
};

export default Orders;
